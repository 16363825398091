
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/65272e1ccdb126e5/packages/express-checkout-widget-ooi/src/components/ExpressCheckoutWidget/Widget/index.tsx';

  import {
    withStyles
  } from '@wix/native-components-infra';

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
var I18nextProvider = React.Fragment;


    var PureExperimentsProvider = React.Fragment;
    var ExperimentsProvider = React.Fragment;
    

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

  import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';

var BaseUiEnvironmentProviderWrapper = React.Fragment;

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
  TPAComponentsProvider,
  BaseUiEnvironmentProviderWrapper,
}


  import stylesParams from '/home/builduser/work/65272e1ccdb126e5/packages/express-checkout-widget-ooi/src/components/ExpressCheckoutWidget/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://244597332e474a0cae62fe5bcd78439f@sentry.wixpress.com/2364',
      id: '244597332e474a0cae62fe5bcd78439f',
      projectName: 'express-checkout-widget-ooi',
      teamName: 'payments',
      errorMonitor: true,
    };

  var translationsConfig = {"enabled":false,"icuEnabled":true};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: '54fb025c-61dc-4286-87c7-0ac416c58744',
    name: 'ExpressCheckoutWidget',
    withErrorBoundary: false,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    
          loadableReady: process.env.browser ? require("@wix/yoshi-flow-editor/loadable").loadableReady : null,
          chunkLoadingGlobal: process.env.chunkLoadingGlobal,
          
    component: UserComponent,
    loadChunks
  };
