import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { CashierExpressCheckoutWidget } from '@wix/cashier-express-checkout-widget/dist/src/lazy';
import { WidgetProps as ExpressWidgetProps } from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import { IWixWindowViewMode } from '@wix/native-components-infra/dist/src/types/types';
import loadable from '@loadable/component';

const CashierExpressCheckoutWidgetDemoLib = loadable.lib(
  () => import('@wix/cashier-express-checkout-widget/dist/src/demo'),
);

export type ControllerProps = {
  widgetProps: ExpressWidgetProps;
  isBlocksEditor: boolean;
  viewMode: IWixWindowViewMode;
  _publicData: { APP: { [p: string]: any }; COMPONENT: { [p: string]: any } };
};

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  let widgetProps: ExpressWidgetProps;

  if (props.viewMode === 'Editor') {
    widgetProps = props._publicData.COMPONENT.widgetProps;
  } else {
    widgetProps = props.widgetProps;
  }

  return (
    <div data-hook="ExpressCheckoutWidget-wrapper">
      {!widgetProps || props.isBlocksEditor ? (
        <CashierExpressCheckoutWidgetDemoLib>
          {({ CashierExpressCheckoutWidgetDemo }) => (
            <CashierExpressCheckoutWidgetDemo />
          )}
        </CashierExpressCheckoutWidgetDemoLib>
      ) : (
        <CashierExpressCheckoutWidget {...widgetProps} />
      )}
    </div>
  );
};

export default Widget;
